import React from "react"
import { graphql, navigate } from "gatsby"
import { Box, Flex, Button, Icon } from "@chakra-ui/core"
import { SectionHeading, Paragraph, Bold, Illustration } from "../components/ui-elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as blogStyles from "./blog.module.css"
import topBackground from "../images/top-background.jpg"

const Blog = ({ data }) => {
  const blog = data.blogPost;
  
  return (
    <Layout>
      <SEO description={blog.description} title={blog.title} />
      {blog.image 
        ? <Illustration
            src={blog.image}
            alt={blog.title}
            w="100%"
            h="calc(100vh - 300px)"
            objectFit="cover"
            />
        : <Illustration 
            src={topBackground}
            alt="default image"
            w="100%" 
            h="calc(100vh - 300px)"            
            objectFit="cover" /> 
      }            
      <Flex direction="row"  >        
        <Button m={8}  onClick={() => navigate(-1)} bg="theme-white" _hover={{ color: "theme-green" }}>
          <Icon size="30px" name="chevron-left" />
          Go Back
        </Button>
      </Flex>  
      <Box px="4" pb="16" w="100%" maxW="containers.xl" mx="auto">
        <Box p="4">
          <SectionHeading>{blog.title}</SectionHeading>          
        </Box>
        <Flex justifyContent="flex-end" >
          <Paragraph mt="2" mb="4">
            created by
            <Bold color="theme-purple" pl="4">{blog.author}</Bold> 
          </Paragraph>
          <Paragraph mt="2" mb="4" px="2" color="theme-purple">|</Paragraph>
          <Paragraph mt="2" mb="4">
            {new Date (blog.createdAt).toDateString().slice(4)}
          </Paragraph>
        </Flex>        
        <Flex >
          <Box className={blogStyles.container} dangerouslySetInnerHTML={{ __html: blog.body }}/>          
        </Flex>
      </Box>
    </Layout>
  );
};

export default Blog;

export const postQuery = graphql`
  query SinglePostByPath($id: String!){
    blogPost(id: {eq: $id}){      
      author
      body
      description
      id
      image
      title
      createdAt        
    }
  }
`